// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        year: 'Today',
        title: 'Data Professional',
        duration: '5 months',
        details: "I have successfully obtained the Google Data Analytics and Google Advanced Data Analytics " +
            "certifications, demonstrating my expertise in the field. Currently, I am pursuing a Masters of Science in " +
            "Data Analytics at Western Governors University, further enhancing my knowledge and skills in this domain. " +
            "My proficiency in data analytics encompasses a diverse set of tools, including Python, R, SQL, Microsoft Excel, " +
            "and Tableau. With a keen eye for detail, I excel in analyzing intricate datasets, uncovering valuable insights, " +
            "and providing actionable recommendations to facilitate data-driven decision-making. I possess a proven " +
            "ability to extract, clean, and transform data, leveraging advanced statistical techniques to identify key " +
            "patterns and trends. Combining problem-solving acumen with effective communication skills, I am committed " +
            "to delivering accurate and meaningful data analysis. My dedication lies in harnessing the power of data to " +
            "optimize business processes and drive strategic initiatives."
    },
    {
        year: '2022',
        title: 'Software Developer',
        duration: '8 months',
        details: "I have developed full-stack applications using Java, Spring, SQL, JavaScript, and React, demonstrating " +
            "proficiency across the entire software development lifecycle. Additionally, I have actively contributed to " +
            "the assessment process by evaluating and grading the work of junior developers. I possess hands-on experience " +
            "in deploying live websites and working with Git for efficient version control. My dedication to professional " +
            "growth fuels my continuous exploration of new technologies and thorough research into emerging trends, " +
            "ensuring I stay informed about the most valuable areas for future learning."
    },
    {
        year: '2022',
        title: 'Dev10 Associate',
        duration: '3 months',
        details:"As one of the fortunate few selected for the exclusive Dev10 Associate training program, I underwent " +
            "an intensive and fully paid curriculum led by seasoned industry professionals committed to fostering the " +
            "development of future engineers. Through engaging in multiple projects and undertaking a substantial " +
            "capstone, I received comprehensive and rigorous training, empowering me with the expertise and proficiency " +
            "needed to thrive in the field of software development."
    },
    {
        year: '2019',
        title: '911 Operator',
        duration: '2 years',
        details:"I played a vital role in emergency response operations. I effectively processed both emergency and " +
            "non-emergency telephone calls, ensuring prompt and appropriate dispatch of emergency personnel. Utilizing " +
            "a multi-channel radio system and computer aided dispatch (CAD) system, I efficiently coordinated and " +
            "monitored communications for police, fire, and medical services. Additionally, I provided accurate " +
            "directions to citizens, field personnel, and other law enforcement agencies, gathering essential " +
            "information to determine the nature of each call for service. I adeptly monitored various electronic " +
            "equipment and radio channels while performing additional duties in support of team objectives. Through my " +
            "dedicated service, I contributed to the overall effectiveness and success of emergency response efforts."
    },
    {
        year: '2017',
        title: 'Bachelors Degree in Music Education',
        duration: '6 years',
        details:" I actively sought avenues to foster creativity while honing my ability to thrive in high-pressure " +
            "situations. This journey taught me the importance of meticulous preparation and collaboration within " +
            "larger teams to accomplish significant objectives. Through leading and instructing groups ranging from as " +
            "small as two individuals to as large as 150, I gained invaluable experience in guiding and facilitating " +
            "musical education and performance."
    },
    {
        year: '2016',
        title: 'First Line of Code',
        duration: '"Hello World"',
        details:"This year, I took my first step into the world of technology by writing my very first line of code. " +
            "It was a moment of excitement and curiosity that sparked my interest and set me on a path of exploration " +
            "and learning in the field of programming."
    },
]

