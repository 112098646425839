export default [
    {
        title: 'Data Cleaning',
        imgUrl: '/assets/data_cleaning.jpg',
        stack: ['python', 'pandas','numpy','seaborn','scipy.stats','sklearn', 'matplotlib'],
        link: '/assets/D206_PA.html',
    },
    {
        title: 'Exploratory Data Analysis',
        imgUrl: '/assets/EDA_cover.jpg',
        stack: ['python','pandas', 'numpy','scipy.stats','plotnine'],
        link: '/assets/D207_PA.html',
    },
    {
        title: 'SQL Data Manipulation',
        imgUrl: '/assets/sql_cover.jpg',
        stack: ['SQL'],
        link: '/assets/dataman_sql.html',
    },
    {
        title: 'Tableau Dashboard',
        imgUrl: '/assets/tableau_dash.jpg',
        stack: ['Tableau'],
        link: 'https://public.tableau.com/shared/2RTG7T3SY?:display_count=n&:origin=viz_share_link',
    },
    {
        title: 'Paw-pals',
        imgUrl: '/assets/pawpals.jpg',
        stack: ['HTML/CSS','Java', 'Spring', 'JavaScript','SQL'],
        link: 'https://github.com/GranadosSebastian/paw-pals',
    },
    {
        title: 'Portfolio Website',
        imgUrl: '/assets/websitescreenshot.jpg',
        stack: ['HTML/CSS', 'Javascript', 'React','Tailwind'],
        link: 'https://github.com/GranadosSebastian/portfolio-website',
    },

]